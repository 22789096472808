import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { LoadMoreButton } from 'components/buttons/LoadMoreButton';
import { CampaignsTiles } from 'components/campaign/CampaignsTiles';
import { SortingDropdown } from 'components/inputs/SortingDropdown';
import { H4 } from 'components/typography/Headers';
import { Text2 } from 'components/typography/Texts';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { useHomeCampaigns } from 'services/useCampaigns';

import { CampaignsNotFound } from './CampaignsNotFound/CampaignsNotFound';
import { ClearFiltersPanel } from './filters/ClearFiltersPanel';

const Container = styled.div`
  padding: 48px;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CampaignsList = () => {
  const { t } = useTranslation('campaign');
  const { hasActiveFilters, resetFilters, pageState, filters, updateFilters } = useCampaignsFilters();
  const { state, fetchNextPage } = useHomeCampaigns(filters, pageState);
  const hasCampaigns = state.campaigns.length > 0;

  return (
    <Container>
      <HeaderContainer>
        <H4>{t('campaigns')}</H4>
        <SortContainer>
          <Text2>{t('sort-by')}</Text2>
          <SortingDropdown sortType={filters.sortType} onSortChange={sortType => updateFilters({ sortType })} />
        </SortContainer>
      </HeaderContainer>
      {hasActiveFilters && <ClearFiltersPanel />}
      {hasCampaigns ? (
        <>
          <CampaignsTiles campaigns={state.campaigns} />
          <LoadMoreButton hasNextPage={state.hasNextPage} loading={state.loading} onClick={fetchNextPage} />
        </>
      ) : (
        !state.loading && <CampaignsNotFound resetFilters={resetFilters} />
      )}
    </Container>
  );
};
