import { useEffect, useState } from 'react';

import { useAuth } from 'providers/AuthProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';

const DEFAULT_LOCATION = { lat: 40.7128, lon: -74.006 };

export const useUserLocation = () => {
  const { logged } = useAuth();
  const { currentUser } = useGlobalData();
  const [userLocation, setUserLocation] = useState(DEFAULT_LOCATION);

  useEffect(() => {
    if (logged) {
      if (currentUser?.location) {
        setUserLocation({
          lat: currentUser.location.lat ?? DEFAULT_LOCATION.lat,
          lon: currentUser.location.lon ?? DEFAULT_LOCATION.lon,
        });
      } else {
        setUserLocation(DEFAULT_LOCATION);
      }
    } else {
      setUserLocation(DEFAULT_LOCATION);
    }
  }, [logged, currentUser]);

  return userLocation;
};
