import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import binocularsIcon from 'assets/svg/binoculars-icon.svg';
import { H5 } from 'components/typography/Headers';

import { AvailableCampaigns } from './AvailableCampaigns';
import { EasyGiving } from './EasyGiving';

const Container = styled.div`
  padding: 48px 48px 0 48px;
`;

const NoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 300px;
`;

interface Props {
  resetFilters: () => void;
}

export const CampaignsNotFound = ({ resetFilters }: Props) => {
  const { t } = useTranslation('campaign');
  return (
    <Container>
      <NoContentContainer>
        <img src={binocularsIcon} alt={'binoculars'} />
        <H5>{t('no-results')}</H5>
      </NoContentContainer>
      <AvailableCampaigns resetFilters={resetFilters} />
      <EasyGiving />
    </Container>
  );
};
