import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import arrowIcon from 'assets/svg/red-right-arrow.svg';
import { RedTextButton } from 'components/buttons/RedTextButton';
import { CampaignsTiles } from 'components/campaign/CampaignsTiles';
import { H4 } from 'components/typography/Headers';
import { noContentFilters } from 'providers/CampaignFiltersProvider';
import { useHomeCampaigns } from 'services/useCampaigns';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Button = styled(RedTextButton)`
  padding: 0;
`;

const Icon = styled.img`
  padding-left: 12px;
`;

interface Props {
  resetFilters: () => void;
}

export const AvailableCampaigns = ({ resetFilters }: Props) => {
  const { t } = useTranslation('campaign');
  const { state } = useHomeCampaigns(noContentFilters, 'Filtered');

  return (
    <Container>
      <HeaderContainer>
        <H4>{t('campaigns')}</H4>
        <Button onClick={resetFilters}>
          {t('show-all')} <Icon src={arrowIcon} />
        </Button>
      </HeaderContainer>
      <CampaignsTiles campaigns={state.campaigns} />
    </Container>
  );
};
