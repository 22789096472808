import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { FeaturedSection } from 'components/containers/FeaturedSection';
import { CampaignsFiltersProvider } from 'providers/CampaignFiltersProvider';
import { useGlobalData } from 'providers/GlobalDataProvider';
import { useModal } from 'providers/ModalProvider';
import { useFeaturedCampaigns } from 'services/useCampaigns';

import { CampaignsFilters } from './components/CampaignsFilters';
import { CampaignsList } from './components/CampaignsList';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  margin: 0 auto;

  ${theme.mq.desktop} {
    flex-direction: column;
    margin: 0 24px;
    overflow-x: hidden;
  }

  ${theme.mq.phone} {
    margin: 0 12px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
  border-left: 1px solid ${theme.color.darkGray};
`;

const FiltersContainer = styled.div`
  width: 300px;
  padding: 48px 48px 0 24px;
`;

export const Campaigns = () => {
  const { t } = useTranslation('campaign');
  const { campaignCategories } = useGlobalData();
  const featuredCampaigns = useFeaturedCampaigns();
  const { showGetApp } = useModal();

  return (
    <CampaignsFiltersProvider>
      <Container>
        <FiltersContainer>
          <CampaignsFilters categories={campaignCategories} />
        </FiltersContainer>
        <ContentContainer>
          <FeaturedSection
            title={t('start-campaign-title')}
            description={t('start-campaign-desc')}
            buttonLabel={t('start-campaign-btn')}
            onButtonClick={() => showGetApp({ appAreaType: 'ADD_CAMPAIGN' })}
            state={featuredCampaigns.state}
          />
          <CampaignsList />
        </ContentContainer>
      </Container>
    </CampaignsFiltersProvider>
  );
};
