import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import WhiteButton from 'components/buttons/WhiteButton';
import { H2 } from 'components/typography/Headers';

const Container = styled.div`
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;

export const Thanks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('user');
  return (
    <Container>
      <H2>{t('thanks.subtitle')}</H2>
      <WhiteButton onClick={() => navigate('/')}>{t('thanks.ok')}</WhiteButton>
    </Container>
  );
};
