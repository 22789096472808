import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import iphone from 'assets/svg/iphone.svg';
import RedButton from 'components/buttons/RedButton';
import { H1, H4 } from 'components/typography/Headers';
import { useModal } from 'providers/ModalProvider';

const Container = styled.div`
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-image: url(${iphone});
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const TextContainer = styled.div`
  max-width: 60%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const Description = styled(H4)`
  font-weight: normal;
  white-space: break-spaces;
`;

const Button = styled(RedButton)`
  max-width: 260px;
`;

export const EasyGiving = () => {
  const { t } = useTranslation('campaign');
  const { showGetApp } = useModal();

  return (
    <Container>
      <TextContainer>
        <H1>{t('easy-giving.title')}</H1>
        <Description>{t('easy-giving.desc')}</Description>
        <Button onClick={() => showGetApp()}>{t('easy-giving.btn')}</Button>
      </TextContainer>
    </Container>
  );
};
