import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { H3 } from 'components/typography/Headers';
import { useLoginPopup } from 'features/account';
import { useEnterOfferPopup } from 'features/item/hooks/useEnterOfferPopup';
import { useAuth } from 'providers/AuthProvider';
import { CampaignDto } from 'services/Campaign/campaignService.dto';
import { ItemDto } from 'services/Item/itemService.dto';
import { useAuctionData } from 'services/Item/useAuctionData';

import AuctionInfo from './auction/AuctionInfo';
import { usePlaceBidPopup } from './auction/hooks/usePlaceBidPopup';
import { ButtonsSection } from './ButtonsSection';
import CampaignInfo from './CampaignInfo';
import ConditionInfo from './ConditionInfo';
import QuantityInfo from './QuantityInfo';
import ShippingInfo from './ShippingInfo';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 36px;
  border: 1px solid ${theme.color.darkGray};
  border-radius: 5px;

  ${theme.mq.huge} {
    padding: 24px;
  }

  ${theme.mq.desktop} {
    padding: 12px;
  }
`;

const PriceContainer = styled.div`
  margin: 24px 0;
`;

interface Props {
  item: ItemDto;
  campaign?: CampaignDto;
  refreshItemData?: () => void;
}

export const ItemDetails = (props: Props) => {
  const { item, campaign, refreshItemData } = props;
  const navigate = useNavigate();
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();

  const isAuction = item.sellingMethod === 'AUCTION';

  const { content: auction, refreshData: refreshAuctionData } = useAuctionData(item.id, isAuction);

  const onBidPlaced = () => {
    refreshAuctionData();
    if (refreshItemData) {
      refreshItemData();
    }
  };

  const onOfferPlaced = () => {
    if (refreshItemData) {
      refreshItemData();
    }
  };

  const { showPlaceBidPopup } = usePlaceBidPopup();
  const { showEnterOfferPopup } = useEnterOfferPopup();

  const handleBuyClick = () => {
    if (logged) {
      navigate(`/checkout/${item.id}`);
    } else {
      showLoginPopup();
    }
  };

  return (
    <Container>
      <CampaignInfo campaign={campaign} />
      <PriceContainer>
        {!!item.price && <H3 data-testid={'item-price'}>${item.price}</H3>}
        <ConditionInfo item={item} />
      </PriceContainer>
      <ShippingInfo item={item} />
      {isAuction && auction && <AuctionInfo auction={auction} />}
      <ButtonsSection
        item={item}
        auction={auction}
        onBuyClick={handleBuyClick}
        onMakeOfferClick={() => showEnterOfferPopup(item.id, onOfferPlaced)}
        onPlaceBidClick={() => showPlaceBidPopup(auction!!, onBidPlaced)}
        onBidPlaced={onBidPlaced}
      />
      <QuantityInfo item={item} />
    </Container>
  );
};
