import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import checkedIcon from 'assets/svg/checked-icon.svg';

export const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  appearance: none;
  border-radius: 8px;
  background-color: ${theme.color.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.darkGray};
  cursor: pointer;

  &:checked {
    background-color: ${theme.color.red};
    box-shadow: 0 0 0 1px white inset;
    position: relative;
  }

  &:checked::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(${checkedIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
