import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import { CampaignDto, CampaignStatsDto, MoneyCollectedDto } from './campaignService.dto';

class CampaignService {
  static BASE_URL = `${apiUrl}/api/campaign`;

  fetchCampaignDetails(campaignId: number): AxiosPromise<CampaignDto> {
    const url = `${CampaignService.BASE_URL}/public/campaigns/${campaignId}`;
    return apiService.get(url);
  }

  fetchBulkCampaignDetails(campaignIds: number[]): AxiosPromise<CampaignDto[]> {
    const url = `${CampaignService.BASE_URL}/public/campaigns/bulk`;
    return apiService.post(url, { ids: campaignIds });
  }

  fetchUserMoneyCollected(userId: number): AxiosPromise<MoneyCollectedDto> {
    const url = `${CampaignService.BASE_URL}/public/user/${userId}/money-collected`;
    return apiService.get(url);
  }

  fetchCurrentUserMoneyCollected(): AxiosPromise<MoneyCollectedDto> {
    const url = `${CampaignService.BASE_URL}/user/current/money-collected`;
    return apiService.get(url);
  }

  fetchMyCampaignStats(): AxiosPromise<CampaignStatsDto> {
    const url = `${CampaignService.BASE_URL}/my-campaigns/stats`;
    return apiService.get(url);
  }

  fetchMyCampaigns(page: number, size: number, sort?: string): AxiosPromise<PageableType<CampaignDto>> {
    const url = `${CampaignService.BASE_URL}/my-campaigns`;
    return apiService.get(url, {
      size: size,
      page: page,
      sort: sort ? sort : 'id,desc',
    });
  }

  fetchCategories() {
    const url = `${CampaignService.BASE_URL}/public/categories`;
    return apiService.get(url);
  }
}

const campaignService = new CampaignService();

export { campaignService, CampaignService };
