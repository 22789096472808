import { Outlet } from 'react-router-dom';

import ScrollToTop from 'navigation/extensions/ScrollToTop';
import TrackLastPublicRoute from 'navigation/extensions/TrackLastPublicRoute';
import AppProviders from 'providers/AppProviders';

export const RootWrapper = () => {
  return (
    <AppProviders>
      <ScrollToTop />
      <TrackLastPublicRoute />
      <Outlet />
    </AppProviders>
  );
};
