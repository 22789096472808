import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

import { Menu } from './Menu';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${theme.maxWidthContainer}px;
  width: 100%;
  margin: 0 auto;

  ${theme.mq.desktop} {
    flex-direction: column;
    margin: 0 24px;
    overflow-x: hidden;
  }

  ${theme.mq.phone} {
    margin: 0 12px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 300px;
  border-left: 1px solid ${theme.color.darkGray};
  padding: 48px 24px 48px 48px;

  ${theme.mq.huge} {
    padding-left: 24px;
    padding-right: 24px;
  }

  ${theme.mq.desktop} {
    border-left: 0;
    border-top: 1px solid ${theme.color.darkGray};
    padding-left: 0;
    padding-right: 0;
  }

  ${theme.mq.phone} {
    padding: 24px 0;
  }
`;

export const PageWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <Menu />
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
