import { ConversationProvider } from 'providers/ConversationProvider';

import { MessengerPanel } from './components/MessengerPanel';

export const Messenger = () => {
  return (
    <ConversationProvider>
      <MessengerPanel />
    </ConversationProvider>
  );
};
