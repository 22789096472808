import { Outlet, useOutletContext } from 'react-router-dom';

import { PageWrapper } from 'features/my-profile/components/PageWrapper';

export const MyProfileWrapper = () => {
  const context = useOutletContext();
  return (
    <PageWrapper>
      <Outlet context={context} />
    </PageWrapper>
  );
};
