import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { CampaignDto } from 'services/Campaign/campaignService.dto';

import CampaignTile from './campaign-tile/CampaignTile';

const TilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 24px;
  padding: 24px 0 24px 0;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

interface Props {
  campaigns: CampaignDto[];
}
export const CampaignsTiles = ({ campaigns }: Props) => {
  const navigate = useNavigate();
  return (
    <TilesContainer>
      {campaigns.map(campaign => (
        <CampaignTile key={campaign.id} campaign={campaign} onClick={() => navigate(`/campaigns/${campaign.id}`)} />
      ))}
    </TilesContainer>
  );
};
