import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import paymentDeclined from 'assets/svg/payment-declined.svg';
import RedButton from 'components/buttons/RedButton';
import { H2 } from 'components/typography/Headers';
import { Text1 } from 'components/typography/Texts';

const Container = styled.div`
  margin: 24px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 24px;
`;

const StyledButton = styled(RedButton)`
  margin: 24px 0;
  min-width: 315px;
`;

export const PaymentDeclined = () => {
  const { t } = useTranslation('error');
  return (
    <Container>
      <Image src={paymentDeclined} alt='' />
      <H2>{t('payment-declined.title')}</H2>
      <Text1>{t('payment-declined.description-header')}</Text1>
      <Text1>{t('payment-declined.description')}</Text1>
      <Link to={'/give-phone'}>
        <StyledButton>{t('payment-declined.button')}</StyledButton>
      </Link>
    </Container>
  );
};
