import { useEffect } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

import { useLoginPopup } from 'features/account';
import { useAuth } from 'providers/AuthProvider';
import { getLastPublicRoute } from 'utils/localStorageData';

export const PrivateRouteWrapper = () => {
  const { logged } = useAuth();
  const { showLoginPopup } = useLoginPopup();
  const context = useOutletContext();

  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  const navigateBack = () => {
    navigate(getLastPublicRoute() || '/');
  };

  useEffect(() => {
    if (!logged) {
      showLoginPopup({ onLogged: refreshPage, onClose: navigateBack });
    }
  }, [logged]);

  return <Outlet context={context} />;
};
