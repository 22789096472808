import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { ScreenContainer } from 'components/containers/ScreenContainer';
import { FooterVariant, HeaderVariant } from 'components/nav/navigationVariant';

export type ScreenContextType = {
  setScreenVariant: (headerVariant: HeaderVariant, footerVariant: FooterVariant) => void;
};

export const ScreenWrapper = () => {
  const [headerVariant, setHeaderVariant] = useState<HeaderVariant>('Default');
  const [footerVariant, setFooterVariant] = useState<FooterVariant>('Default');

  const setScreenVariant = (headerVariant: HeaderVariant, footerVariant: FooterVariant) => {
    setHeaderVariant(headerVariant);
    setFooterVariant(footerVariant);
  };

  const context: ScreenContextType = { setScreenVariant };

  return (
    <ScreenContainer headerVariant={headerVariant} footerVariant={footerVariant}>
      <Outlet context={context} />
    </ScreenContainer>
  );
};
