import { useTranslation } from 'react-i18next';

import { InfoSection, InfoSectionWrapper, StyledTitle } from 'components/containers/InfoSection';

export const ActivateAccountError = () => {
  const { t } = useTranslation('account');

  return (
    <InfoSectionWrapper>
      <InfoSection onSuccess={false} />
      <StyledTitle>{t('account-activation-error')}</StyledTitle>
    </InfoSectionWrapper>
  );
};
