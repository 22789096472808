import { useTranslation } from 'react-i18next';

import { Header } from 'features/my-profile/components/Header';
import { ReturnFromProfileButton } from 'features/my-profile/components/ReturnFromProfileButton';
import { PaymentProvider } from 'providers/PaymentProvider';

import { PaymentContainer } from './components/PaymentContainer';
import { PaymentModule } from './PaymentModule';

export const PaymentPage = () => {
  const { t } = useTranslation('myProfile');

  return (
    <div data-testid={'my-profile-payment'}>
      <ReturnFromProfileButton />
      <Header>{t('payment.header')}</Header>
      <PaymentProvider>
        <PaymentContainer>
          <PaymentModule />
        </PaymentContainer>
      </PaymentProvider>
    </div>
  );
};
