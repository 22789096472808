import { AxiosPromise } from 'axios';

import PageableType from 'services/pageableType';
import { apiService, apiUrl } from 'services/utils/apiService';

import {
  SearchCampaignDto,
  SearchCampaignsParams,
  SearchItemDto,
  SearchMarketplaceItemsParams,
} from './searchService.dto';

class SearchService {
  BASE_URL = `${apiUrl}/api/search`;

  searchItems(params: SearchMarketplaceItemsParams): AxiosPromise<PageableType<SearchItemDto>> {
    const url = `${this.BASE_URL}/public/items`;
    return apiService.get(url, {
      ...params,
    });
  }

  searchCampaigns(params: SearchCampaignsParams): AxiosPromise<PageableType<SearchCampaignDto>> {
    const url = `${this.BASE_URL}/public/campaigns`;
    const category = params.category?.join();

    const request = {
      ...params,
      category: category,
    };
    return apiService.get(url, request);
  }
}

const searchService = new SearchService();

export { searchService };
