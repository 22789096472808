import { useEffect } from 'react';

import { CampaignsPageState } from 'providers/CampaignFiltersProvider';
import { useSearchCampaigns } from 'services/Search/useSearchCampaigns';

import { SearchCampaignsParams } from './Search/searchService.dto';

export const useFeaturedCampaigns = () =>
  useSearchCampaigns({ status: 'ACTIVE', sortType: 'RELEVANT', size: 3, page: 0 });

export const useHomeCampaigns = (filters: SearchCampaignsParams, pageState: CampaignsPageState) => {
  const homeCampaigns = useSearchCampaigns({
    ...(pageState === 'Default' ? { status: 'ACTIVE' } : { status: filters.status }),
    sortType: filters.sortType,
    size: filters.size ?? 12,
    page: 0,
    lat: filters.lat,
    lon: filters.lon,
    category: filters.category,
    ...(filters.sortType === 'RELEVANT' && pageState === 'Default' && { offset: 3 }),
  });

  const { refresh } = homeCampaigns;

  useEffect(() => {
    refresh();
  }, [filters]);

  return homeCampaigns;
};
