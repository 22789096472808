import { useLocation } from 'react-router-dom';

import { ObjectNotFound } from 'components/ui-elements/ObjectNotFound';

import CheckoutSuccessContent from './CheckoutSuccessContent';
import { CheckoutSuccessState } from './checkoutSuccessState';

export const CheckoutSuccess = () => {
  const { state }: { state: CheckoutSuccessState } = useLocation();

  if (!state?.transaction || !state?.purchase) return <ObjectNotFound testId={'state-error'} />;

  return <CheckoutSuccessContent purchase={state.purchase} transaction={state.transaction} />;
};
