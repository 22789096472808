import { useTranslation } from 'react-i18next';

import { Header } from 'features/my-profile/components/Header';
import { ReturnFromProfileButton } from 'features/my-profile/components/ReturnFromProfileButton';
import { ShippingAddressProvider } from 'providers/ShippingAddressProvider';

import { AddressesContainer } from './components/AddressesContainer';
import { ShippingAddressesModule } from './ShippingAddressesModule';

export const ShippingAddressPage = () => {
  const { t } = useTranslation('myProfile');

  return (
    <div data-testid={'my-profile-shipping-address'}>
      <ReturnFromProfileButton />
      <Header>{t('shipping-address.header')}</Header>
      <ShippingAddressProvider>
        <AddressesContainer>
          <ShippingAddressesModule />
        </AddressesContainer>
      </ShippingAddressProvider>
    </div>
  );
};
