import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withTheme } from 'styled-components';

import GlobalStyle from './assets/styles/GlobalStyle';
import i18n from './assets/translations/config';
import AppRouter from './navigation/AppRouter';

class App extends React.Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <html lang='en-US' />
          <title>Giveo</title>
          <meta name='description' content={i18n.t('app-description')} />
        </Helmet>
        <GlobalStyle />
        <AppRouter />
      </HelmetProvider>
    );
  }
}

export default withTheme(App);
