import i18n from 'i18next';

import hotDealsIcon from 'assets/svg/hot-deals.svg';
import { AppAreaType } from 'services/Notification/notificationService.dto';

export interface NavItemType {
  icon?: string;
  name: string;
  appAreaType?: AppAreaType;
  link?: string;
}

export const navItems: NavItemType[] = [
  {
    name: i18n.t('common:navbar.campaigns'),
    link: '/campaigns',
  },
  {
    icon: hotDealsIcon,
    name: i18n.t('common:navbar.hot-deals'),
    appAreaType: 'MARKETPLACE',
  },
];
