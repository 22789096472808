export const clipPathStyle = `
  polygon(
    100% 0%,
    0% 0%,
    0% 92.72%,
    2% 92.41%,
    4% 92.14%,
    6% 91.89%,
    8% 91.68%,
    10% 91.51%,
    12% 91.39%,
    14% 91.3%,
    16% 91.26%,
    18% 91.27%,
    20% 91.33%,
    22% 91.42%,
    24% 91.57%,
    26% 91.75%,
    28% 91.97%,
    30% 92.23%,
    32% 92.51%,
    34% 92.83%,
    36% 93.16%,
    38% 93.51%,
    40% 93.87%,
    42% 94.24%,
    44% 94.6%,
    46% 94.96%,
    48% 95.3%,
    50% 95.63%,
    52% 95.94%,
    54% 96.21%,
    56% 96.46%,
    58% 96.67%,
    60% 96.84%,
    62% 96.96%,
    64% 97.05%,
    66% 97.08%,
    68% 97.08%,
    70% 97.02%,
    72% 96.93%,
    74% 96.78%,
    76% 96.6%,
    78% 96.38%,
    80% 96.12%,
    82% 95.84%,
    84% 95.52%,
    86% 95.19%,
    88% 94.84%,
    90% 94.48%,
    92% 94.11%,
    94% 93.75%,
    96% 93.39%,
    98% 93.05%,
    100% 92.72%
  )
`;
