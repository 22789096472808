import styled from 'styled-components';

import { clipPathStyle } from 'assets/styles/featuredWave';
import { theme } from 'assets/styles/theme';
import ButtonBase from 'components/buttons/ButtonBase';
import { FeaturedCarousel } from 'components/carousel/FeaturedCarousel';
import { H2, H4 } from 'components/typography/Headers';
import Spinner from 'components/ui-elements/Spinner';
import { useCampaignsFilters } from 'providers/CampaignFiltersProvider';
import { CampaignsState } from 'services/Search/useSearchCampaigns';

import { renderErrorOrSpinner } from './renderErrorOrSpinner';

const Container = styled.div`
  background-color: ${theme.color.red};
  clip-path: ${clipPathStyle};
  width: 100%;
  height: 560px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 48px 48px 125px;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const Title = styled(H2)`
  color: ${theme.color.white};
`;

const Description = styled(H4)`
  color: ${theme.color.white};
  white-space: break-spaces;
`;

const Button = styled(ButtonBase)`
  max-width: 260px;
  background-color: ${theme.color.white};
`;

interface Props {
  title: string;
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
  state: CampaignsState;
}

export const FeaturedSection = ({ title, description, buttonLabel, onButtonClick, state }: Props) => {
  const { pageState } = useCampaignsFilters();

  if (pageState !== 'Default') return null;
  if (state.loading) return renderErrorOrSpinner(<Spinner displayLabel={true} />);
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Button onClick={onButtonClick}>{buttonLabel}</Button>
      </TextContainer>
      <FeaturedCarousel campaigns={state.campaigns} />
    </Container>
  );
};
