import { useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { ScreenContextType } from 'navigation/wrapper/screen/ScreenWrapper';

export const LogoOnlyScreenWrapper = () => {
  const { setScreenVariant } = useOutletContext<ScreenContextType>();

  useEffect(() => {
    setScreenVariant('LogoOnly', 'None');
  }, []);

  return <Outlet />;
};
