import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { useUserLocation } from 'features/campaign/hooks/useUserLocation';
import { SearchCampaignsParams } from 'services/Search/searchService.dto';
import { GeocodingDto } from 'services/Shipment/shipmentService.dto';
import { CampaignSortType, CampaignStatusType } from 'services/statusTypes';

export const DEFAULT_SORT_TYPE = 'RELEVANT';
export type CampaignsPageState = 'Default' | 'Filtered';

export interface FiltersParams extends SearchCampaignsParams {
  cityName?: string;
}

export const noContentFilters: SearchCampaignsParams = {
  status: 'ACTIVE',
  category: [],
  sortType: DEFAULT_SORT_TYPE,
  size: 6,
};

const initFilters: FiltersParams = {
  category: [],
  sortType: DEFAULT_SORT_TYPE,
  status: undefined,
};

interface CampaignsFiltersContextType {
  filters: FiltersParams;
  pageState: CampaignsPageState;
  updateFilters: (params: {
    sortType?: CampaignSortType;
    category?: string[];
    status?: CampaignStatusType;
    location?: GeocodingDto;
  }) => void;
  resetFilters: () => void;
  removeFilter: (filterType: 'category' | 'status' | 'location', value?: string) => void;
  hasActiveFilters: boolean;
}

const CampaignsFiltersContext = createContext<CampaignsFiltersContextType>(null!);

const CampaignsFiltersProvider = ({ children }: PropsWithChildren) => {
  const userLocation = useUserLocation();

  const [pageState, setPageState] = useState<CampaignsPageState>('Default');
  const [filters, setFilters] = useState<FiltersParams>(initFilters);

  const determinePageState = (newFilters: FiltersParams) => {
    const hasActiveFilters =
      (newFilters.category && newFilters.category.length > 0) ||
      newFilters.status ||
      newFilters.lat !== undefined ||
      newFilters.lat !== userLocation.lat;

    setPageState(hasActiveFilters ? 'Filtered' : 'Default');
  };

  const updateFilters = ({
    sortType,
    category,
    status,
    location,
  }: {
    sortType?: CampaignSortType;
    category?: string[];
    status?: CampaignStatusType;
    location?: GeocodingDto;
  }) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (sortType) {
        newFilters.sortType = sortType;
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        if (sortType === 'NEAREST') {
          newFilters.lat = userLocation.lat;
          newFilters.lon = userLocation.lon;
        }
      }

      if (category) {
        newFilters.category = category;
        setPageState('Filtered');
      }

      if (status) {
        newFilters.status = status !== 'ALL' ? status : undefined;
        setPageState('Filtered');
      }

      if (location) {
        newFilters.sortType = 'NEAREST';
        newFilters.lat = location.coordinates.latitude;
        newFilters.lon = location.coordinates.longitude;
        newFilters.cityName = location.city;
        setPageState('Filtered');
      }

      return newFilters;
    });
  };

  const removeFilter = (filterType: 'category' | 'status' | 'location', value?: string) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (filterType === 'category' && newFilters.category) {
        newFilters.category = newFilters.category.filter(category => category !== value);
      } else if (filterType === 'status') {
        newFilters.status = undefined;
      } else if (filterType === 'location') {
        newFilters.lat = undefined;
        newFilters.lon = undefined;
        newFilters.cityName = undefined;
        newFilters.sortType = DEFAULT_SORT_TYPE;
      }

      determinePageState(newFilters);
      return newFilters;
    });
  };

  const resetFilters = () => {
    setFilters(initFilters);
    setPageState('Default');
  };

  const hasActiveFilters =
    ((filters.category && filters.category.length > 0) ||
      filters.status !== undefined ||
      filters.lat !== userLocation.lat) &&
    pageState === 'Filtered';

  return (
    <CampaignsFiltersContext.Provider
      value={{ filters, pageState, updateFilters, resetFilters, removeFilter, hasActiveFilters }}>
      {children}
    </CampaignsFiltersContext.Provider>
  );
};

const useCampaignsFilters = () => useContext(CampaignsFiltersContext);

export { CampaignsFiltersProvider, useCampaignsFilters };
