import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { theme } from 'assets/styles/theme';
import CampaignTile from 'components/campaign/campaign-tile/CampaignTile';
import { CampaignDto } from 'services/Campaign/campaignService.dto';

import 'swiper/css';
import 'swiper/css/pagination';

const StyledSwiper = styled(Swiper)`
  width: 615px;
  height: 450px;
  border-radius: 5px;

  & .swiper-slide {
    max-width: 300px;
  }

  & .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid ${theme.color.white};
    opacity: 0.8;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  & .swiper-pagination-bullet-active {
    width: 24px;
    height: 24px;
    background-color: ${theme.color.white};
    opacity: 1;
  }
`;

interface Props {
  campaigns: CampaignDto[];
}
export const FeaturedCarousel = ({ campaigns }: Props) => {
  const swiperRef = useRef<SwiperRef>(null);
  const navigate = useNavigate();

  const handleSlideClick = (campaign: CampaignDto) => {
    navigate(`/campaigns/${campaign.name}/${campaign.id}`);
  };

  const handleAutomaticSlideClick = () => {
    if (swiperRef.current) {
      if (swiperRef.current.swiper.activeIndex === 1) {
        swiperRef.current.swiper.slideTo(0);
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleAutomaticSlideClick, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledSwiper
      ref={swiperRef}
      data-testid={'campaign-carousel'}
      slidesPerView={2}
      spaceBetween={12}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}>
      {campaigns.map((campaign, index) => (
        <SwiperSlide key={index}>
          <CampaignTile campaign={campaign} onClick={() => handleSlideClick(campaign)} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};
