import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { DropdownInput } from 'features/my-profile/components/FormContainers';
import { CampaignSortType } from 'services/statusTypes';

const StyledDropdown = styled(DropdownInput)`
  width: 200px;

  & .react-select__control {
    cursor: pointer;
  }

  & .react-select__option {
    cursor: pointer;
  }

  & .react-select__option--is-selected {
    background-color: ${theme.color.red};
  }

  & .react-select__option--is-focused {
    background-color: rgba(252, 104, 111, 0.2);
  }

  & .react-select__option--is-selected.react-select__option--is-focused {
    background-color: ${theme.color.red};
  }
`;

interface Props {
  sortType: CampaignSortType;
  onSortChange: (sortType: CampaignSortType) => void;
}

export const SortingDropdown = ({ sortType, onSortChange }: Props) => {
  const { t } = useTranslation('campaign');

  const sortOptions = [
    { value: 'RELEVANT', label: t('option.relevant') },
    { value: 'RECENT', label: t('option.recent') },
    { value: 'NEAREST', label: t('option.nearest') },
    { value: 'BEST_MATCH', label: t('option.best-match') },
  ];
  return (
    <StyledDropdown
      value={sortType}
      options={sortOptions}
      onChange={newSortType => onSortChange(newSortType as CampaignSortType)}
      variant={'UPDATE'}
    />
  );
};
