import { AxiosPromise } from 'axios';

import { apiService, apiUrl } from 'services/utils/apiService';
import { getOrGenerateDeviceId } from 'utils/deviceId';

import {
  CurrentUserDto,
  PublicUserDto,
  SignUpDto,
  UserLocationDto,
  UserShippingAddressDto,
  UserShippingAddressRequestDto,
} from './userService.dto';

class UserService {
  static BASE_URL = `${apiUrl}/api/user`;

  fetchCurrentUser(): AxiosPromise<CurrentUserDto> {
    const url = `${UserService.BASE_URL}/users/current`;
    return apiService.get(url);
  }

  fetchPublicUser(userId: number): AxiosPromise<PublicUserDto> {
    const url = `${UserService.BASE_URL}/public/users/${userId}`;
    return apiService.get(url);
  }

  fetchBulkPublicUsers(userIds: number[]): AxiosPromise<PublicUserDto[]> {
    const url = `${UserService.BASE_URL}/public/users/bulk`;
    return apiService.get(url, { ids: userIds.join(',') });
  }

  activateAccount(signupToken: string): AxiosPromise {
    const url = `${UserService.BASE_URL}/public/confirm-signup`;
    return apiService.post(url, { signupToken });
  }

  signUp(signUpData: SignUpDto) {
    const url = `${UserService.BASE_URL}/public/signup`;
    return apiService.post(url, { ...signUpData, deviceId: getOrGenerateDeviceId() });
  }

  confirmSignUp(email: string, pin: string) {
    const url = `${UserService.BASE_URL}/public/confirm-signup`;
    return apiService.post(url, { email, pin });
  }

  resendActivationToken(email: string) {
    const url = `${UserService.BASE_URL}/public/activation-token-reset`;
    return apiService.post(url, { email });
  }

  fetchShippingAddresses(): AxiosPromise<UserShippingAddressDto[]> {
    const url = `${UserService.BASE_URL}/users/current/shipping-addresses`;
    return apiService.get(url);
  }

  updateShippingAddress(
    addressId: number,
    addressUpdate: UserShippingAddressRequestDto
  ): AxiosPromise<UserShippingAddressDto[]> {
    const url = `${UserService.BASE_URL}/users/current/shipping-addresses/${addressId}`;
    return apiService.patch(url, addressUpdate);
  }

  addShippingAddress(newAddress: UserShippingAddressRequestDto): AxiosPromise<UserShippingAddressDto[]> {
    const url = `${UserService.BASE_URL}/users/current/shipping-addresses`;
    return apiService.post(url, newAddress);
  }

  deleteShippingAddress(addressId: number): AxiosPromise<UserShippingAddressDto[]> {
    const url = `${UserService.BASE_URL}/users/current/shipping-addresses/${addressId}`;
    return apiService.delete(url);
  }

  setUserLocation(location: UserLocationDto) {
    const url = `${UserService.BASE_URL}/users/current/location`;
    return apiService.put(url, location);
  }
}

const userService = new UserService();

export { userService, UserService };
